import _defineProperty from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _slicedToArray from "D:\\a\\1\\s\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _everyInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/every";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { DATA_TYPE_OBJECT, dateValueShape, dataTypeShapeWithDateTime, intlMessageShape, nestedTooltipShape } from '@jutro/prop-types';
import { publish, JUTRO_TOPICS } from '@jutro/events';
import { FieldComponent, DateField } from '@jutro/components';
import { Grid } from '@jutro/layout';
import { TimeField } from '../TimeField/TimeField';
import { formatValueFromDataType, formatValueToDataType, isValidDate, isValidTime } from './helpers';
import styles from "./DateTimeField.module.css";
function splitProps(props) {
  var _context;
  return _reduceInstanceProperty(_context = _Object$entries(props)).call(_context, function (_ref, _ref2) {
    var dateProps = _ref.dateProps,
      timeProps = _ref.timeProps;
    var _ref3 = _slicedToArray(_ref2, 2),
      key = _ref3[0],
      value = _ref3[1];
    var newDateProps = dateProps;
    var newTimeProps = timeProps;
    if (key.match(/Time/)) {
      newTimeProps = _objectSpread(_defineProperty({}, key.replace('Time', ''), value), timeProps);
    } else {
      newDateProps = _objectSpread(_defineProperty({}, key, value), dateProps);
    }
    return {
      dateProps: newDateProps,
      timeProps: newTimeProps
    };
  }, {
    timeProps: {},
    dateProps: {}
  });
}
export var DateTimeField = function DateTimeField(props) {
  var id = props.id,
    onValueChange = props.onValueChange,
    dataType = props.dataType,
    value = props.value,
    defaultValue = props.defaultValue,
    model = props.model,
    path = props.path,
    showTime = props.showTime,
    dataPath = props.dataPath,
    className = props.className,
    hideLabel = props.hideLabel,
    hideLabelTime = props.hideLabelTime,
    disabled = props.disabled,
    readOnly = props.readOnly,
    isClearable = props.isClearable,
    labelClassName = props.labelClassName,
    secondaryLabelClassName = props.secondaryLabelClassName,
    controlClassName = props.controlClassName,
    labelPosition = props.labelPosition,
    phone = props.phone,
    phoneWide = props.phoneWide,
    tablet = props.tablet,
    timeIntervals = props.timeIntervals,
    timeFormat = props.timeFormat,
    other = _objectWithoutProperties(props, ["id", "onValueChange", "dataType", "value", "defaultValue", "model", "path", "showTime", "dataPath", "className", "hideLabel", "hideLabelTime", "disabled", "readOnly", "isClearable", "labelClassName", "secondaryLabelClassName", "controlClassName", "labelPosition", "phone", "phoneWide", "tablet", "timeIntervals", "timeFormat"]);
  var _splitProps = splitProps(other),
    dateProps = _splitProps.dateProps,
    timeProps = _splitProps.timeProps;
  var compositeValue = formatValueFromDataType(value, showTime);
  useEffect(function () {
    if (!value && defaultValue) {
      var newValue = formatValueFromDataType(defaultValue, showTime);
      handleChange(newValue);
    }
    publish(JUTRO_TOPICS.COMPONENT_LOADED, FieldComponent.fieldEventFormatter(_objectSpread({
      componentName: DateTimeField.displayName
    }, props)));
  }, []);
  var isAllLabelsHidden = useMemo(function () {
    var _context2;
    return _everyInstanceProperty(_context2 = [hideLabel, hideLabelTime]).call(_context2, function (flag) {
      return flag;
    });
  }, [hideLabel, hideLabelTime]);
  var handleChange = function handleChange(val) {
    var newValue = _objectSpread({}, compositeValue, {}, val);
    if (isClearable && !val) {
      newValue = undefined;
    }
    var formattedValue = formatValueToDataType(newValue, dataType, showTime);
    if (!onValueChange) {
      return;
    }
    onValueChange(formattedValue, model || path, {
      id: id,
      dataPath: dataPath
    });
    publish(JUTRO_TOPICS.VALUE_CHANGED, FieldComponent.fieldEventFormatter(_objectSpread({}, props, {
      value: formattedValue
    })));
  };
  var renderDateField = function renderDateField() {
    var selectedValue;
    if (isValidDate(compositeValue)) {
      var year = compositeValue.year,
        month = compositeValue.month,
        day = compositeValue.day;
      selectedValue = {
        year: year,
        month: month,
        day: day
      };
    }
    var onChange = function onChange(newValue) {
      if (!newValue) {
        handleChange(undefined);
        return;
      }
      var year = newValue.year,
        month = newValue.month,
        day = newValue.day;
      handleChange({
        year: year,
        month: month,
        day: day
      });
    };
    var dateFieldProps = _objectSpread({
      disabled: disabled,
      readOnly: readOnly,
      isClearable: isClearable,
      secondaryLabelClassName: secondaryLabelClassName,
      controlClassName: controlClassName,
      labelPosition: labelPosition,
      phone: phone,
      phoneWide: phoneWide,
      tablet: tablet
    }, dateProps, {
      id: "".concat(id, "-dateField"),
      dataType: DATA_TYPE_OBJECT,
      value: selectedValue,
      onValueChange: onChange,
      showTime: false,
      hideLabel: isAllLabelsHidden,
      labelClassName: cx(_defineProperty({}, styles.hideVisibility, hideLabel), labelClassName)
    });
    return React.createElement(DateField, dateFieldProps);
  };
  var renderTimeField = function renderTimeField() {
    var selectedValue;
    if (isValidTime(compositeValue)) {
      var hour = compositeValue.hour,
        minute = compositeValue.minute;
      selectedValue = {
        hour: hour,
        minute: minute
      };
    }
    var onChange = function onChange(newValue) {
      if (!newValue) {
        handleChange(undefined);
        return;
      }
      var hour = newValue.hour,
        minute = newValue.minute;
      handleChange({
        hour: hour,
        minute: minute
      });
    };
    var timeFieldProps = _objectSpread({
      disabled: disabled,
      readOnly: readOnly,
      isClearable: isClearable,
      secondaryLabelClassName: secondaryLabelClassName,
      controlClassName: controlClassName,
      labelPosition: labelPosition,
      phone: phone,
      phoneWide: phoneWide,
      tablet: tablet
    }, timeProps, {
      timeIntervals: timeIntervals,
      timeFormat: timeFormat,
      id: "".concat(id, "-timeField"),
      value: selectedValue,
      onValueChange: onChange,
      hideLabel: isAllLabelsHidden,
      labelClassName: cx(_defineProperty({}, styles.hideVisibility, hideLabelTime), labelClassName)
    });
    return React.createElement(TimeField, timeFieldProps);
  };
  var gridColumns = useMemo(function () {
    return labelPosition === 'top' && showTime ? ['2fr', '1fr'] : [];
  }, [labelPosition, showTime]);
  return React.createElement(Grid, {
    columns: gridColumns,
    phone: {
      columns: []
    },
    className: className
  }, renderDateField(), showTime && renderTimeField());
};
DateTimeField.displayName = 'DateTimeField';
DateTimeField.propTypes = _objectSpread({}, TimeField.propTypes, {}, DateField.propTypes, {
  dataType: dataTypeShapeWithDateTime.isRequired,
  value: dateValueShape,
  defaultValue: dateValueShape,
  showTime: PropTypes.bool,
  onTimeBlur: PropTypes.func,
  onTimeFocus: PropTypes.func,
  requiredTime: PropTypes.bool,
  showErrorsTime: PropTypes.bool,
  showRequiredTime: PropTypes.bool,
  showOptionalTime: PropTypes.bool,
  labelTime: intlMessageShape,
  secondaryLabelTime: intlMessageShape,
  hideLabelTime: PropTypes.bool,
  tooltipTime: PropTypes.oneOfType([PropTypes.string, nestedTooltipShape]),
  placeholderTime: intlMessageShape
});
DateTimeField.defaultProps = _objectSpread({}, TimeField.defaultProps, {}, DateField.defaultProps, {
  dataType: DATA_TYPE_OBJECT,
  showTime: true
});
DateTimeField.__docgenInfo = {
  componentName: "DateTimeField",
  packageName: "@jutro/lab-preview-date",
  description: "",
  displayName: "DateTimeField",
  methods: [],
  actualName: "DateTimeField",
  metadataType: "field",
  props: {
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: false
        }, {
          value: "'string'",
          computed: false
        }, {
          value: "'date-time'",
          computed: false
        }]
      },
      required: false,
      description: "Returned data type",
      defaultValue: {
        value: "'object'",
        computed: false
      }
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "instanceOf",
          value: "Date"
        }, {
          name: "shape",
          value: {
            year: {
              name: "number",
              required: true
            },
            month: {
              name: "number",
              required: true
            },
            day: {
              name: "number",
              required: true
            },
            hour: {
              name: "number",
              required: false
            },
            minute: {
              name: "number",
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Date and time to display"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "instanceOf",
          value: "Date"
        }, {
          name: "shape",
          value: {
            year: {
              name: "number",
              required: true
            },
            month: {
              name: "number",
              required: true
            },
            day: {
              name: "number",
              required: true
            },
            hour: {
              name: "number",
              required: false
            },
            minute: {
              name: "number",
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Set the default field value on render if there is a default value; needs onValueChange to work"
    },
    showTime: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Show timepicker",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    onTimeBlur: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when blur event is fired in time picker"
    },
    onTimeFocus: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when focus event is fired in time picker"
    },
    requiredTime: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, time picker is required"
    },
    showErrorsTime: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Show errors for time picker"
    },
    showRequiredTime: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Show required indicator for time picker"
    },
    showOptionalTime: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Show optional indicator for time picker"
    },
    labelTime: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Intl message for time picker label"
    },
    secondaryLabelTime: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Intl message for time picker label"
    },
    hideLabelTime: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Hides the label on any layout for time picker"
    },
    tooltipTime: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "custom",
          raw: "nestedTooltipShape"
        }]
      },
      required: false,
      description: "Tooltip for time picker"
    },
    placeholderTime: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Intl message for time picker placeholder"
    }
  },
  composes: ["../TimeField/TimeField", "@jutro/components"]
};